export default {
  props: {
    itemValue: Number,
    showProgressBar: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cartValue() {
      return this.$store.state.cart.cartData.prices.discountLimitPriceWithVat
    },
    itemExists() {
      return !!this.itemValue
    },
    isCartLoaded() {
      return this.$store.state.cart.isLoaded
    },
    isCartFreeShipping() {
      return (
        this.$store.state.cart.cartData &&
        this.$store.state.cart.cartData.coupon &&
        this.$store.state.cart.cartData.coupon.free_shipping
      )
    },
    isActive() {
      return this.remainder <= 0
    },
    progress() {
      return this.isCartLoaded
        ? this.cartValue < this.itemValue && !this.isCartFreeShipping
          ? 100 * (this.cartValue / this.itemValue)
          : 100
        : 0
    },
    remainder() {
      return this.isCartLoaded && !this.isCartFreeShipping ? this.itemValue - this.cartValue : 0
    },
    remainingPrice() {
      return this.$currencyFormat(this.remainder)
    },
  },
}
