import { render, staticRenderFns } from "./CheckoutFreeGift.vue?vue&type=template&id=c122e83e&"
import script from "./CheckoutFreeGift.js?vue&type=script&lang=js&"
export * from "./CheckoutFreeGift.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CheckoutFreeGift.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fcvb%2Fcomponents%2Fcart%2FCheckoutFreeGift%2FCheckoutFreeGift.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "@/themes/cvb/components/content/DiscountsOverview/DiscountsOverview.lang.cs.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fcvb%2Fcomponents%2Fcart%2FCheckoutFreeGift%2FCheckoutFreeGift.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports