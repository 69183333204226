import CheckoutFreeGift from '@/themes/base/components/cart/CheckoutFreeGift'
import find from 'lodash/find'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

export default {
  extends: CheckoutFreeGift,
  data() {
    return {
      steps: this.$t('DiscountsOverview.items'),
    }
  },
  computed: {
    cartValue() {
      return this.$store.state.cart.cartData.prices.itemsWithTax || 0
    },
    sortedDiscountSteps() {
      return sortBy(map(Object.entries(this.steps), entry => entry[1]), 'discountThreshold')
    },
    activeStep() {
      const steps = [...this.sortedDiscountSteps].reverse()
      return find(steps, step => step.discountThreshold <= this.cartValue)
    },
    hasActiveStep() {
      return Boolean(this.activeStep)
    },
    nextStep() {
      return find(this.sortedDiscountSteps, step => step.discountThreshold > this.cartValue)
    },
    hasNextStep() {
      return Boolean(this.nextStep)
    },
    goalValue() {
      return this.hasNextStep ? this.nextStep.discountThreshold : this.activeStep.discountThreshold
    },
  },
}
