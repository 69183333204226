import CheckoutFreeItem from '@theme/components/cart/CheckoutFreeItem'

export default {
  components: { CheckoutFreeItem },
  computed: {
    textFreeDelivery() {
      return this.$t('CheckoutFreeDelivery.active')
    },
  },
}
